

// phone number tooltip related changes.
// set tooltip for the header.
frappe.ready(function(){
    tippy_utils.set_tooltip("header-mobile-tooltip", 'template-tooltip', {
        trigger: 'click',
        placement: 'bottom-end',
        theme: 'custom-lite',
        allowHTML: true,
        arrow: false,
        maxWidth: 410,
        offset: [0, 17],
        interactive: true
    });
    // set tooltip for the footer.
    tippy_utils.set_tooltip("footer-mobile-tooltip", 'template-tooltip',{
        trigger: 'click',
        placement: 'top-start',
        theme: 'custom-lite',
        allowHTML: true,
        arrow: false,
        maxWidth: 396,
        offset: [-22, 27],
        interactive: true
    });

    slider_utils.apply_slider_wide_settings();
    bond_assets.lazyload.init();
    bootstrap_settings.apply();
});
