/*
    Device Controller
*/
frappe.provide("bond_assets.device");

$.extend(bond_assets.device, {
    is_ipad_device: function(){
        return $(window).width() < 991;
    },

    is_mobile_device: function(){
        return window.innerWidth < 575;
    }
});