/*
    local storage cache
*/

frappe.provide("_cache");

$.extend(_cache, {
    clear_landing_cache: function(){
        let search = new URLSearchParams(window.location.search);
        localStorage.removeItem("referral_code");
        localStorage.removeItem("zip_code");
        localStorage.removeItem("ad_reference_name");
        localStorage.removeItem("line_of_insurance");
        if(search.get("referral_code")){
            localStorage.setItem("referral_code", search.get("referral_code"));
        }
    },

    clear_portal_cache: function(){

    },

    clear_erp_cache: function(){

    },

    cache_url_params: function(){
        let search = new URLSearchParams(window.location.search);
        if(search.get("referral_code")){
            localStorage.setItem("referral_code", search.get("referral_code"));
        }
        
        if(search.get("zip_code")){
            localStorage.setItem("zip_code", search.get("zip_code"));
        }

        if(search.get("line_of_insurance")){
            localStorage.setItem("line_of_insurance", search.get("line_of_insurance"));
        }

        if(search.get("ad_reference_name")){
            localStorage.setItem("ad_reference_name", search.get("ad_reference_name"));
        }
    
    }
});